












































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class App extends Vue {
  name = "";

  email = "";

  company = "";

  failed = false;

  succeded = false;

  get nameValid(): boolean {
    return this.name.length > 0;
  }

  get emailValid(): boolean {
    return this.email.length > 0 && this.email.indexOf("@") > -1;
  }

  async register() {
    this.failed = false;
    if (this.emailValid && this.nameValid) {
      const res = await axios.post(
        "https://interest.scrummage.io/api/interest",
        {
          name: this.name,
          email: this.email,
          company: this.company
        }
      );

      if (res.status == 503) {
        this.failed = true;
      } else {
        this.succeded = true;
      }
    }
  }

  created() {
    document.title = "Register your interest";
  }

  interest() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
}
